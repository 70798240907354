import React, { useState, useEffect } from 'react';
import CLGrapgLeftSidebarItem from '../CLGrapgLeftSidebarItem/CLGrapgLeftSidebarItem';
import './CLGrapgLeftSidebarItemHolder.css';
import fluentIcon from '../../../../assets/images/icons/fluentIcon1.png';
import mdiIcon2 from '../../../../assets/images/icons/mdiIcon2.png';
import stackIcon3 from '../../../../assets/images/icons/stackIcon3.png';
import relationshipIcon4 from '../../../../assets/images/icons/relationshipIcon4.png';
import worldIcon5 from '../../../../assets/images/icons/worldIcon5.png';
import targetIcon6 from '../../../../assets/images/icons/targetIcon6.png';
import fluentHighlighted from '../../../../assets/images/icons/fluentHighlighted.png';
import mdiIHighlighted from '../../../../assets/images/icons/mdiHighlighted.png';
import stackHighlighted from '../../../../assets/images/icons/stackHighlighted.png';
import relationHighlighted from '../../../../assets/images/icons/relationshipHighlighted.png';
import worldHighlighted from '../../../../assets/images/icons/worldHighlighted.png';
import targetHighlighted from '../../../../assets/images/icons/targetHighlighted.png';
import CorporateLinksGraphLeftSidebar from '../../CorporateLinksGraphLeftSidebar/CorporateLinksGraphSidebar';
// import GraphButton from '../../../GraphButton/GraphButton';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import LogoIcon from '../../../../assets/icons/Logo-colored.svg';
// import { faChartLine } from '@fortawesome/free-solid-svg-icons';

function CLGrapgLeftSidebarItemHolder({
  setLeftSideButtonType,
  isLoadingShareholders,
  setIsSidebarHidden
}) {
  // Set the first item as active by default
  const [activeItem, setActiveItem] = useState('Officers & Companies');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const updateSidebarVisibility = () => {
      const sidebarElement = document.querySelector('.corporate-links-graph-left-sidebar-main');
      if (sidebarElement) {
        const isHidden = window.getComputedStyle(sidebarElement).display === 'none';
        setIsSidebarHidden(isHidden);
      }
    };

    // Check visibility on load and on resize
    updateSidebarVisibility();
    window.addEventListener('resize', updateSidebarVisibility);

    return () => {
      window.removeEventListener('resize', updateSidebarVisibility);
    };
  }, []);

  const items = [
    { highlightedIcon: fluentHighlighted, text: 'All Shareholders', icon: fluentIcon },
    { highlightedIcon: mdiIHighlighted, text: 'Debt Ratio', icon: mdiIcon2 },
    { highlightedIcon: stackHighlighted, text: 'Total Liabilities', icon: stackIcon3 },
    { highlightedIcon: relationHighlighted, text: 'Show Entity Link', icon: relationshipIcon4 },
    { highlightedIcon: worldHighlighted, text: 'Nationality', icon: worldIcon5 },
    { highlightedIcon: targetHighlighted, text: 'Status', icon: targetIcon6 },
  ];

  const handleItemClick = text => {
    setActiveItem(text);
    setLeftSideButtonType(text);
    if (window.innerWidth <= 768) {
      setSidebarOpen(true);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div>
      <CorporateLinksGraphLeftSidebar
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        activeItem={activeItem}
      />

      <div className="corporate-links-graph-left-sidebar-main">
        {items.map(item => (
          <CLGrapgLeftSidebarItem
            key={item.text}
            icon={item.icon}
            text={item.text}
            highlightedIcon={item.highlightedIcon}
            isActive={activeItem === item.text}
            onClick={() => handleItemClick(item.text)}
            isLoadingShareholders={isLoadingShareholders}
          />
        ))}
      </div>
    </div>
  );
}

export default CLGrapgLeftSidebarItemHolder;
