import React from 'react';
import './GraphButton.css';

const GraphButton = ({ onClick, variant, icon, customStyles, text }) => {
  return (
    <button
      className={`Garph-button ${variant}`}
      onClick={onClick}
      style={customStyles}
    >
      {icon && <span className="button-icon">{icon}</span>}
      {text && <span className="button-text">{text}</span>}
    </button>
  );
};

export default GraphButton;
