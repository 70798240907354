import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faChartLine } from '@fortawesome/free-solid-svg-icons';
import {
  faBars,
  faCog,
  faShareSquare,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import './GraphRightSideBar.css';
import GraphButton from '../GraphButton/GraphButton';
import fingerprintIcon from '../../assets/images/icons/fingerprint.png'

const BottomMenu = ({ zoomIn, zoomValue, zoomOut, exportSelectedGraph, isSidebarHidden, setIsFilterOpened }) => {
  // const [isOpen, setIsOpen] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsBurgerMenu(window.innerWidth <= 900);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   // Check initial window size
  //   handleResize();

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  // const toggleMenu = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <div className="graph-Right-side-bar-menu-container">
      {/* {isBurgerMenu ? (
        <>
          <button
            className="graph-Right-side-bar-burger-menu"
            onClick={toggleMenu}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className={`graph-right-sidebar ${isOpen ? 'open' : ''}`}>
            <button
              className="graph-Right-side-bar-menu-close-menu"
              onClick={toggleMenu}
            >
              &times;
            </button>
            <ul className="graph-Right-side-bar-menu-items">
              <li onClick={exportSelectedGraph}>
                <FontAwesomeIcon icon={faShareSquare} /> Share
              </li>
              <li>
                <FontAwesomeIcon icon={faCog} /> Settings
              </li>
            </ul>
          </div>
        </>
      ) : (
        <> */}
      <div className="graph-Right-side-bar-btn-group">
        {/* <GraphButton
          icon={<FontAwesomeIcon icon={faChartLine} />}
          variant="primary"
          text="Graphs"
          customStyles={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '12px',
            borderRadius: '50%',
            cursor: 'pointer',
            width: '50px',
            height: '50px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        />
        <div>
          <div style={{ marginBottom: '10px' }}>
            <GraphButton
              icon={<FontAwesomeIcon icon={faExternalLinkAlt} />}
              variant="primary"
              text="Export"
              customStyles={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                borderRadius: '50%',
                cursor: 'pointer',
                width: '50px',
                height: '50px',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={exportSelectedGraph}
            />
          </div> */}
        <div className="graph-Right-side-bar-graph-btns-plus-minus">
          <div style={{ border: '1px sloid black', display: 'flex', alignItems: 'center' }}>
            <img src={fingerprintIcon} alt="icon" style={{ width: '25px', height: '30px', marginRight: '2px' }} />
            <span style={{ fontSize: '14px', fontWeight: '600', marginLeft: '6px' }}>Probe Digital</span>
          </div>
          <div style={{ border: '1px sloid black', display: 'flex', width: '130px' }}>
            <GraphButton
              icon={<FontAwesomeIcon icon={faMinus} style={{ width: '16px', height: '16px' }} />}
              variant="primary"
              customStyles={{
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
                // fontSize: '12px',
                borderRadius: '50%',
                cursor: 'pointer',
                padding: '10px'
                // width: '50px',
                // height: '50px',
                // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={zoomOut}
            />
            <span style={{ marginLeft: '10px', marginRight: '10px', marginTop: '5px', fontSize: '14px', fontWeight: '600' }}>{zoomValue} %</span>
            <GraphButton
              icon={<FontAwesomeIcon icon={faPlus} style={{ width: '16px', height: '16px' }} />}
              variant="primary"
              customStyles={{
                // display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
                // fontSize: '12px',
                borderRadius: '50%',
                cursor: 'pointer',
                padding: '10px',
                // width: '50px',
                // height: '50px',
                // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              }}
              onClick={zoomIn}
            />
          </div>
        </div>
        {/* </div> */}
        {isSidebarHidden && (
          <GraphButton
            icon={<FontAwesomeIcon icon={faChartLine} style={{ width: '16px', height: '16px' }} />}
            variant="primary"
            text="Filters"
            onClick={() => { setIsFilterOpened(true) }}
            customStyles={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              borderRadius: '50%',
              cursor: 'pointer',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          />
        )}
      </div>
      {/* </> */}
      {/* )} */}
    </div>
  );
};

export default BottomMenu;
