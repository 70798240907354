import React from 'react';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';

// Function to apply custom styles received via props
const useCustomStyles = (
  iconSize,
  borderRadius,
  borderColor,
  backgroundColor,
  width,
  height,
  margin,
  marginRight,
  disabled,
) => ({
  background: backgroundColor,
  color: '#212529',
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '22px',
  textTransform: 'none',
  border: `1px solid ${borderColor}`,
  borderRadius,
  padding: '5px 15px',
  margin,
  boxShadow: 'none',
  marginRight,
  width: width,
  height: height,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

function ProbeButton({
  id,
  iconSrc,
  name,
  iconSize,
  borderRadius,
  borderColor,
  backgroundColor,
  width,
  height,
  fontSize,
  onClick,
  fontColor,
  disabled,
  margin,
  type,
  marginRight,
  iconType,
  buttonDivDisplay,
  buttonDivJustifyContent,
}) {
  const customStyles = useCustomStyles(
    iconSize,
    borderRadius,
    borderColor,
    backgroundColor,
    width,
    height,
    margin,
    marginRight,
    disabled
  );

  return (
    <div
      style={{
        display: buttonDivDisplay && buttonDivDisplay,
        justifyContent: buttonDivJustifyContent && buttonDivJustifyContent,
      }}
    >
      <Button
        type={type && type}
        id={id && id}
        variant="outlined"
        disabled={disabled}
        startIcon={
          iconSrc && !name && iconType !== 'svg' ? (
            <Icon style={{ width: iconSize, height: iconSize }}>
              <img
                src={iconSrc}
                alt="icon"
                style={{ width: iconSize, height: 'auto' }}
              />
            </Icon>
          ) : null
        }
        style={customStyles}
        onClick={onClick && onClick}
      >
        {(iconSrc && iconType === 'png') && (
          <img
            src={iconSrc}
            alt="icon"
            style={{
              width: '15px',
              height: '17.1px',
              marginRight: name ? '8px' : '0', 
            }}
          />
        )}
        {iconSrc && !name && iconType === 'svg' ? (
          iconSrc
        ) : (
          <span
            style={{
              color: fontColor,
              fontWeight: '400',
              fontSize: fontSize ? fontSize : '1em',
            }}
          >
            {name}
          </span>
        )}
      </Button>
    </div>
  );
}

export default ProbeButton;
