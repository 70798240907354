import React, { useState, useEffect, useContext } from 'react';
import './CESAdvancedFilter.css';
import DateRangeSlider from './CESFilterSlider/CESFilterSlider';
import { AppContext } from '../../../../context/Context';
import ProbeButton from '../../../../components/ProbeButton/ProbeButton';

const CESAdvancedFilter = () => {
  const minCessationDate = 1800;
  const maxCessationDate = 2024;
  const minCreationDate = 1800;
  const maxCreationDate = 2024;
  const { corporateLinksAdvanceFilters, setCorporateLinksAdvanceFilters } =
    useContext(AppContext);

  // Temporary state for filters
  const [tempFilterCompany, setTempFilterCompany] = useState({
    title: '',
    company_status: '',
    company_number: '',
    company_type: '',
    nationality: '',
    postalCode: '',
    creationDateRange: [minCreationDate, maxCreationDate],
    cessationDateRange: [minCessationDate, maxCessationDate],
  });

  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const handleSelectChange = e => {
    const { name, value } = e.target;
    setTempFilterCompany(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleCreationDateRangeChange = newRange => {
    setTempFilterCompany(prevFilters => ({
      ...prevFilters,
      creationDateRange: newRange,
    }));
  };

  const handleCessationDateRangeChange = newRange => {
    setTempFilterCompany(prevFilters => ({
      ...prevFilters,
      cessationDateRange: newRange,
    }));
  };

  useEffect(() => {
    // Check if any field in the filters object has a value
    const isAnyFieldSelected =
      tempFilterCompany?.company_status ||
      tempFilterCompany?.company_number ||
      tempFilterCompany?.title ||
      tempFilterCompany?.nationality ||
      tempFilterCompany?.postalCode ||
      tempFilterCompany?.creationDateRange[0] !== minCreationDate ||
      tempFilterCompany?.creationDateRange[1] !== maxCreationDate ||
      tempFilterCompany?.cessationDateRange[0] !== minCessationDate ||
      tempFilterCompany?.cessationDateRange[1] !== maxCessationDate ||
      Object.values(tempFilterCompany?.company_status).some(company_status => company_status) ||
      Object.values(tempFilterCompany?.company_type).some(type => type);

    // Set the search enabled state
    setIsSearchEnabled(isAnyFieldSelected);

  }, [tempFilterCompany]);

  const applyFilters = () => {
    console.log(tempFilterCompany)
    setCorporateLinksAdvanceFilters({
      ...corporateLinksAdvanceFilters,
      officerFilters: null,
      companyFilters: tempFilterCompany,
    });
  };

  return (
    <div className="company-advance-filter-container">
      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <label for="title" class="form-label filter-label">Company Name</label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="title"
            placeholder="Company Name"
            name="title"
            value={tempFilterCompany?.title}
            onChange={handleSelectChange}
          />
        </div>
        <div className="company-advance-filter-col">
          <label for="company_number" class="form-label filter-label">Company Number</label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="company_number"
            placeholder="Company Number"
            name="company_number"
            value={tempFilterCompany?.company_number}
            onChange={handleSelectChange}
          />
        </div>
      </div>
      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <label for="nationality" class="form-label filter-label">Nationality:</label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="nationality"
            name="nationality"
            value={tempFilterCompany?.nationality}
            onChange={handleSelectChange}
          >
            <option value="" selected disabled>Nationality</option>
            <option>Chesterfield</option>
            <option>Grimsby</option>
            <option>Milton Keynes</option>
            <option>London</option>
            <option>Leamington SPA</option>
            <option>Manchester</option>
            <option>Reading</option>
          </select>
        </div>
        <div className="company-advance-filter-col">
          <label for="postalCode" class="form-label filter-label">Postal Code</label>
          <input
            className="form-control company-person-advance-filter-form-control"
            id="postalCode"
            placeholder="Post Code"
            name="postalCode"
            value={tempFilterCompany?.postalCode}
            onChange={handleSelectChange}
          />
        </div>
      </div>
      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <label for="company_status" class="form-label filter-label">Company Status:</label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="company_status"
            name="company_status"
            value={tempFilterCompany?.company_status}
            onChange={handleSelectChange}
          >
            <option value="" selected disabled>company_status</option>
            <option>Active</option>
            <option>Dissolved</option>
            <option>Dormant</option>
            <option>In Liquidation</option>
          </select>
        </div>
        <div className="company-advance-filter-col">
          <label for="company_type" class="form-label filter-label">Company Type:</label>
          <select
            className="form-control company-person-advance-filter-form-control"
            id="company_type"
            name="company_type"
            value={tempFilterCompany?.company_type}
            onChange={handleSelectChange}
          >
            <option value="" selected disabled>Type</option>
            <option>All Private Companies</option>
            <option>PUL with Share Capitals</option>
            <option>PUL without Share Capitals</option>
          </select>
        </div>
      </div>
      <div className="company-advance-filter-row">
        <div className="company-advance-filter-col">
          <DateRangeSlider
            labelName={'Creation Period'}
            values={tempFilterCompany.creationDateRange}
            setValues={handleCreationDateRangeChange}
            minDate={minCreationDate}
            maxDate={maxCreationDate}
          />
        </div>
        <div className="company-advance-filter-col">
          <DateRangeSlider
            labelName={'Cessation Period'}
            values={tempFilterCompany.cessationDateRange}
            setValues={handleCessationDateRangeChange}
            minDate={minCessationDate}
            maxDate={maxCessationDate}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <ProbeButton
            width="343px"
            height="40px"
            name="Search"
            borderRadius="24px"
            backgroundColor={isSearchEnabled ? 'linear-gradient(to right, #0466D1, #00D1A9)' : '#8A8A8A'}
            fontColor="white"
            borderColor={isSearchEnabled ? '' : '#8A8A8A'}
            disabled={isSearchEnabled ? false : true}
            onClick={applyFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default CESAdvancedFilter;
