import './CESearchEntities.css';
import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import CompanyItem from '../../../components/CorporateExplorerFilter/CompanyInfoModal/PeopleAndContacts/CompanyItem';
import InsightCard from '../../../components/CorporateExploreInsights/CEIInsightResultsCard/CEIinsightResultsCard';
import CESAdvancedFilter from './CESAdvancedFilter/CESAdvancedFilter';
import CESAdvancedFilterPerson from './CESAdvancedFilterPerson/CESAdvancedFilterPerson';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/Context';
import { red } from '@mui/material/colors';
import { GradientIcon } from '../../../utils/helper';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const CESearchEntitiesSection = ({
  searchContainerRef,
  inputValue,
  setInputValue,
  handleChange,
  selectedSuggestions,
  handleGraphPage,
  isOpen,
  selectedType,
  handleButtonClick,
  isLoadingPage,
  loadingGif,
  suggestions,
  itemClicked,
  showCards = true,
  totalOfficersAndCompanies,
  disabled,
  startIcon,
  isDottedCardClicked,
  setSuggestions,
  setSelectedType,
  setSelectedSuggestions,
  setIsSearching,
  setIsLimitReached,
  selectedActualSuggestions,
}) => {
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(true);
  const [inputFocused, setInputFocused] = useState(false);

  const toggleAdvancedFilter = e => {
    e.preventDefault();
    setShowAdvancedFilter(prev => !prev);
  };

  return (
    <>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div
          ref={searchContainerRef}
          style={{
            width: "100%"
          }}
        >
          <div
            style={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {startIcon && !(inputValue.length > 0) && (
              <div
                className="input-icon"
                style={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  pointerEvents: 'none',
                  color: selectedType ? '#000' : '#aaa',
                  fontSize: '15px'
                }}
              >
                {startIcon}
              </div>
            )}
            <input
              type="text"
              className={`cesearch-entites-search-input ${isDottedCardClicked ? 'placeholder-red' : ''}`}
              value={inputValue}
              placeholder={isDottedCardClicked ? "Select Entity Type above" : (selectedType || 'Enter name and apply filter as required')}
              onFocus={() => setInputFocused(true)}
              // onBlur={handleInputBlur}
              onBlur={() => setInputFocused(false)}

              onChange={handleChange}
              disabled={disabled}
              style={{
                width: "100%",
                paddingLeft: (startIcon && !(inputValue.length > 0)) ? '35px' : '15px',
                border: (inputFocused && !disabled) ? '1px #029CBD solid' : '1px #8A8A8A solid',
              }}
            />
            <div
              style={{
                position: 'absolute',
                right: '7px',
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                flexDirection: 'row',
                gap: '7px'
              }}
            >
              <div
                onClick={() => {
                  setInputValue('')
                  setSuggestions([])
                  setSelectedSuggestions(selectedActualSuggestions)
                  setSelectedType(null)
                  setIsLimitReached(false)
                  setIsSearching(true)
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  paddingRight: '8px',
                  borderRight: '2px solid grey'
                }}
              >

                {inputValue.length > 0 && (
                  <CloseIcon style={{ fontSize: 25, color: 'black' }} />
                )}
              </div>
              <div
                onClick={toggleAdvancedFilter}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
              >

                {inputValue.length > 0 && (
                  <GradientIcon IconComponent={FilterAltIcon} />
                )}
              </div>
            </div>
          </div>
          <div>
            {isLoadingPage && (
              <div>
                <img src={loadingGif} alt="Loading..." width={30} />
              </div>
            )}
          </div>
        </div>
        <div
          className={`cesearch-entites-advanced-filter-container ${!showAdvancedFilter ? 'show' : ''}`}
        >
          {!showAdvancedFilter && (
            <div className="cesearch-entites-advanced-filter">
              <div className="container" style={{ position: 'relative' }}>
                <h2 className="advance-search-heading">{selectedType==='People'?'Advanced people Search':'Advanced Companies Search'}</h2>
                <button className="ce-search-entities-close-button" onClick={() => setShowAdvancedFilter(true)}>
                  X
                </button>
              </div>
              <hr />
              <div className="container">
                {selectedType === 'People' ? (
                  <CESAdvancedFilterPerson />
                ) : (
                  <CESAdvancedFilter />
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default CESearchEntitiesSection;
