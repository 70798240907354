import axios from 'axios';
import { apiConfig, buildUrl } from '../apiConfig';

export const getPscByCompanyNumber = async company_number => {
  try {
    const response = await axios.get(
      buildUrl(apiConfig.base, apiConfig.psc.endpoints.getPscByCompanyNumber),
      { params: { company_number: company_number } }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    return {
      success: false,
      response: error?.response,
      status: error?.response?.status,
    };
  }
};
