import React, { useContext, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './CLGraph.css';
import CLSearchInput from '../CLSearchInput/CLSearchInput';
import { AppContext } from '../../../context/Context';
import CLGrapgLeftSidebarItemHolder from '../CLGraphLeftSidebar/CLGrapgLeftSidebarItemHolder/CLGrapgLeftSidebarItemHolder';
import CLGraphTotalDropdown from '../CLGraphTotalDropdown/CLGraphTotalDropdown';
import ProbeButton from '../../ProbeButton/ProbeButton';
import { useNavigate } from 'react-router';
import { convertGraphToCSV } from '../../../utils/helper';
import URLService from '../../../utils/URLService';
import backArrow from '../../../assets/images/icons/backArrow.png';
import arrow from '../../../assets/images/dashboard/arrow.png';
import person from '../../../assets/images/icons/person.png';
import company from '../../../assets/images/icons/company.png';
import BottomMenu from '../../GraphRightSideBar/GraphRightSideBar';
import BackIcon from '../../../assets/images/icons/BackIcon.png';
import ExportIcon from '../../../assets/images/icons/ExportIcon.png';
import arrowDown from '../../../assets/images/icons/arrowDown.png';
const CLGraph = ({
  graph,
  setLeftSideButtonType,
  selectedGraph,
  setSelectedGraph,
  isLoadingShareholders,
  selectedEntityType,
  setSelectedEntityType,
  rightSidebarOpen,
  searchedEntitiesInGraph,
}) => {
  const navigate = useNavigate();
  const svgRef = useRef();
  const zoomResetRef = useRef();
  const zoomRef = useRef();
  const svgGroupRef = useRef();
  const { clGraph, setClGraph } = useContext(AppContext);
  const [searchProbe, setSearchProbe] = useState('');
  const [isNodeSearching, setIsNodeSearching] = useState(false);
  const { setFinancialAccountsDoc } = useContext(AppContext);
  const [currentZoomTransform, setCurrentZoomTransform] = useState(null);
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [showSlider, setShowSlider] = useState(false);
  const [zoomValue, setZoomValue] = useState(66); // Initialize zoomValue state

  const toggleSlider = () => {
    setShowSlider((prev) => !prev);
  };

  const exportSelectedGraph = () => {
    try {
      let graphcsvfile = convertGraphToCSV(selectedGraph);

      // Create a Blob from the CSV content
      const blob = new Blob([graphcsvfile], {
        type: 'text/csv;charset=utf-8;',
      });

      // Create a download link
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'graph.csv');

      // Append link to the body and trigger the download
      document.body.appendChild(link);
      link.click();

      // Cleanup: remove the link after the download starts
      document.body.removeChild(link);
    } catch (error) { }
  };

  const onSuggestionSelect = node => {
    let searchQuery = node?.id ? node?.id : node?.entityName;
    setSearchProbe(searchQuery);
  };

  const goBackToSearchEntitiesPage = () => {
    const paramSS = URLService.getParam('selectedSuggestions');
    if (paramSS) {
      navigate(`/cl-dashboard/search-entites?selectedSuggestions=${paramSS}`);
    } else {
      navigate('/cl-dashboard/search-entites');
    }
  };

  const zoomIn = () => {
    d3.select(svgRef.current).transition().call(zoomRef.current.scaleBy, 2);
    setZoomValue((prevZoom) => Math.round(Math.min(prevZoom * 2, 200))); // Round to nearest integer
  };

  const zoomOut = () => {
    d3.select(svgRef.current).transition().call(zoomRef.current.scaleBy, 0.5);
    setZoomValue((prevZoom) => Math.round(Math.max(prevZoom / 2, 10))); // Round to nearest integer
  };

  useEffect(() => {
    if (!rightSidebarOpen && svgRef?.current && zoomRef?.current?.scaleBy) {
      d3.select(svgRef.current)
        .transition()
        .call(zoomRef.current.scaleBy, 0.35);
    }
    setZoomValue((prevZoom) => Math.round(Math.max(prevZoom * 0.35, 10))); // Round to nearest integer

  }, [rightSidebarOpen]);

  const filterOptions = [
    { text: 'Shareholders' },
    { text: 'Highlight Searched Entities' },
    { text: 'Show Only Relationship' },
    { text: 'Officers & Companies' },
    { text: 'Only Companies' },
    { text: 'Only Officers' },
  ];

  const handleCheckboxChange = (text) => {
    setSelectedFilter(text); // Set the clicked filter as selected
  };

  const handleFilterButtonClick = () => {
    if (selectedFilter) {
      setLeftSideButtonType(selectedFilter)
    } else {
      console.log('No filter selected');
    }
  };

  useEffect(() => {
    if (!selectedGraph) return;

    const width = window.innerWidth;
    const height = window.innerHeight;
    const spaceBetweenNodes = 50; // Adjust this value to increase or decrease spacing

    const zoom = d3.zoom().on('zoom', event => {
      svgGroupRef.current.attr('transform', event.transform);
      setCurrentZoomTransform(event.transform);
    });

    zoomRef.current = zoom;

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height)
      .call(zoom);

    svgGroupRef.current = svg.append('g');

    if (currentZoomTransform) {
      svg.call(zoom.transform, currentZoomTransform);
    } else {
      // Calculate the center of the graph
      const nodes = selectedGraph.nodes;
      const xValues = nodes.map(node => node.x || 0);
      const yValues = nodes.map(node => node.y || 0);
      const graphCenterX = Math.max(...xValues) + Math.min(...xValues);
      const graphCenterY = Math.max(...yValues) + Math.min(...yValues);

      // Calculate the translation to center the graph in the viewport
      const initialZoomLevel = 0.35; // Initial zoom scale
      const translateX = width / 2 - graphCenterX - 500 * initialZoomLevel;
      const translateY = height / 2 - graphCenterY - 500 * initialZoomLevel;

      // Set the initial zoom transform
      const initialTransform = d3.zoomIdentity
        .translate(translateX, translateY)
        .scale(initialZoomLevel);

      svg.call(zoom.transform, initialTransform);
    }

    const graphData = structuredClone(selectedGraph);

    const relationshipEdgesIds = graphData.meta?.relationshipEdgesIds || [];

    const simulation = d3
      .forceSimulation(graphData.nodes)
      .alpha(4) // Start with a lower alpha
      .alphaDecay(0.09) // Default is 0.0228, adjust if needed for quicker stabilization
      .force(
        'link',
        d3
          .forceLink(graphData.edges)
          .id(d => d.id)
          .distance(150)
      )
      .force('charge', d3.forceManyBody().strength(-300))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force(
        'collision',
        d3.forceCollide().radius(d => spaceBetweenNodes)
      );

    const link = svgGroupRef.current
      .append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(graphData.edges)
      .enter()
      .append('line')
      .attr('stroke-width', 2)
      .attr('stroke', d =>
        relationshipEdgesIds.includes(d.edgeId) ? 'red' : '#999'
      );

    const label = svgGroupRef.current
      .append('g')
      .attr('class', 'labels')
      .selectAll('text')
      .data(graphData.nodes)
      .enter()
      .append('text')
      .attr('dx', 16)
      .attr('dy', '.30em')
      .text(d => {
        if (d.entityType === 'company' && d.entityData && d.entityData.name) {
          return d.entityData.name;
        }
        return d.entityName;
      });

    const node = svgGroupRef.current
      .append('g')
      .attr('class', 'nodes')
      .selectAll('g')
      .data(graphData.nodes)
      .enter()
      .append('g')
      .each(function (d) {
        // 'https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg'
        d3.select(this)
          .append('image')
          .attr('xlink:href', d =>
            d.entityType === 'person'
              ? person
              : company)
          .attr('width', d => (selectedEntityType && d.entityType === selectedEntityType ? 32 : 24)) // Adjust size based on selection
          .attr('height', d => (selectedEntityType && d.entityType === selectedEntityType ? 32 : 24)) // Adjust size based on selection
          .attr('x', d => (selectedEntityType && d.entityType === selectedEntityType ? -16 : -12)) // Centering the icon in the node container (adjust as needed)
          .attr('y', d => (selectedEntityType && d.entityType === selectedEntityType ? -16 : -12)) // Centering the icon in the node container (adjust as needed)
          .style('opacity', d =>
            selectedEntityType && d.entityType !== selectedEntityType ? 0.2 : 1
          )
          .on('mouseover', handleMouseOver)
          .on('mouseout', handleMouseOut)
          .on('click', nodeClicked);
      })
      .call(
        d3
          .drag()
          .on('start', dragStarted)
          .on('drag', dragged)
          .on('end', dragEnded)
      );

    node
      .filter(d => searchedEntitiesInGraph.includes(d.id))
      .attr('class', 'cl-graph-highlighted-node');

    simulation.on('tick', () => {
      link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);
      node.attr('transform', d => `translate(${d.x},${d.y})`);
      label.attr('x', d => d.x).attr('y', d => d.y);
    });

    function dragStarted(event, d) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }

    function dragged(event, d) {
      d.fx = event.x;
      d.fy = event.y;

    }

    function dragEnded(event, d) {
      if (!event.active) simulation.alphaTarget(0);
      // d.fx = null;
      // d.fy = null;
    }

    // Handle node click event
    function nodeClicked(event, d) {
      setFinancialAccountsDoc([]);
      // Stop event propagation so the SVG click handler doesn't fire
      event.stopPropagation();

      // Fix the position of the clicked node (optional)
      d.fx = d.x;
      d.fy = d.y;

      // Highlight the clicked node and its connections
      const connectedNodes = new Set();
      const connectedEdges = new Set();

      function findDirectConnections(nodeId) {
        link.each(function (l) {
          if (l.source.id === nodeId || l.target.id === nodeId) {
            connectedEdges.add(l.edgeId);
            connectedNodes.add(l.source.id);
            connectedNodes.add(l.target.id);
          }
        });
      }

      function findConnectedNodesThroughRelationship(nodeId) {
        link.each(function (l) {
          if (l.source.id === nodeId || l.target.id === nodeId) {
            if (relationshipEdgesIds.includes(l.edgeId)) {
              connectedEdges.add(l.edgeId);
              if (!connectedNodes.has(l.source.id)) {
                connectedNodes.add(l.source.id);
                findConnectedNodesThroughRelationship(l.source.id);
              }
              if (!connectedNodes.has(l.target.id)) {
                connectedNodes.add(l.target.id);
                findConnectedNodesThroughRelationship(l.target.id);
              }
            }
          }
        });
      }

      connectedNodes.add(d.id);

      let isConnectedWithRelationship = false;
      link.each(function (l) {
        if (
          (l.source.id === d.id || l.target.id === d.id) &&
          relationshipEdgesIds.includes(l.edgeId)
        ) {
          isConnectedWithRelationship = true;
        }
      });

      if (isConnectedWithRelationship) {
        findConnectedNodesThroughRelationship(d.id);
      } else {
        findDirectConnections(d.id);
      }

      link
        .style('stroke-opacity', l => (connectedEdges.has(l.edgeId) ? 1 : 0.1))
        .style('stroke-width', l =>
          connectedEdges.has(l.edgeId) ? '3px' : '1px'
        );

      node
        .selectAll('circle')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));

      node
        .selectAll('text')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));

      // Update the state with the clicked node details
      setClGraph(previousState => ({
        ...previousState,
        clickedNode: d,
        isOpenRightSidebar: true,
      }));

      // Zoom to focus on the clicked node
      const zoomLevel = 2;
      const x = d.x;
      const y = d.y;
      const translateX = window.innerWidth / 2 - x * zoomLevel;
      const translateY = window.innerHeight / 2 - y * zoomLevel;

      const transform = d3.zoomIdentity
        .translate(translateX - 200, translateY)
        .scale(zoomLevel);

      svg.transition().duration(600).call(zoom.transform, transform);

      // Optionally, reset the fixed positions if you don't want to freeze the node
      // d.fx = null;
      // d.fy = null;
    }

    // Handle mouse over event to highlight connections
    function handleMouseOver(event, d) {
      const connectedNodes = new Set();
      const connectedEdges = new Set();

      function findDirectConnections(nodeId) {
        link.each(l => {
          if (l.source.id === nodeId || l.target.id === nodeId) {
            connectedEdges.add(l.edgeId);
            connectedNodes.add(l.source.id);
            connectedNodes.add(l.target.id);
          }
        });
      }

      function findConnectedNodesThroughRelationship(nodeId) {
        link.each(l => {
          if (l.source.id === nodeId || l.target.id === nodeId) {
            if (relationshipEdgesIds.includes(l.edgeId)) {
              connectedEdges.add(l.edgeId);
              if (!connectedNodes.has(l.source.id)) {
                connectedNodes.add(l.source.id);
                findConnectedNodesThroughRelationship(l.source.id);
              }
              if (!connectedNodes.has(l.target.id)) {
                connectedNodes.add(l.target.id);
                findConnectedNodesThroughRelationship(l.target.id);
              }
            }
          }
        });
      }

      connectedNodes.add(d.id);

      let isConnectedWithRelationship = false;
      if (relationshipEdgesIds.length > 0) {
        link.each(l => {
          if (
            (l.source.id === d.id || l.target.id === d.id) &&
            relationshipEdgesIds.includes(l.edgeId)
          ) {
            isConnectedWithRelationship = true;
          }
        });
      }

      if (isConnectedWithRelationship) {
        findConnectedNodesThroughRelationship(d.id);
      } else {
        findDirectConnections(d.id);
      }

      link
        .style('stroke-opacity', l => (connectedEdges.has(l.edgeId) ? 1 : 0.1))
        .style('stroke-width', l =>
          connectedEdges.has(l.edgeId) ? '3px' : '1px'
        );

      node
        .selectAll('circle')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));

      node
        .selectAll('text')
        .style('opacity', n => (connectedNodes.has(n.id) ? 1 : 0.1));
    }

    // Handle mouse out event to reset the graph
    function handleMouseOut() {
      link.style('stroke-opacity', 1).style('stroke-width', '1px');
      node.selectAll('circle').style('opacity', 1);
      node.selectAll('text').style('opacity', 1);
    }

    // Reset the graph to its default state
    const resetGraph = () => {
      // Reset all nodes, labels, and links to default opacity and size
      d3.selectAll('circle').style('opacity', 1);
      d3.selectAll('text').style('opacity', 1);
      d3.selectAll('line').style('opacity', 1).style('stroke-width', '2px');

      // Reset zoom and pan (optional if you want to reset the zoom as well)
      svg
        .transition()
        .duration(300)
        .call(zoom.transform, d3.zoomIdentity.scale(1));

      // Clear the searchProbe state to prevent refocusing on the searched node
      setSearchProbe('');

      setCurrentZoomTransform(null);
    };

    // Focus on node if searchProbe matches id or entityName
    if (searchProbe) {
      const matchedNode = graphData.nodes.find(
        node =>
          node.id === searchProbe ||
          (node.entityName &&
            node.entityName.toLowerCase() === searchProbe.toLowerCase())
      );

      if (matchedNode) {
        setIsNodeSearching(true);
        // Trigger the simulation with increased alpha to ensure node positions update
        simulation.alpha(1).restart();

        // Listen for the end of simulation to ensure all nodes are positioned correctly
        simulation.on('end', () => {
          // Highlight the matched node
          node.style('opacity', n => (n.id === matchedNode.id ? 1 : 0.1));
          label.style('display', n => (n.id === matchedNode.id ? 1 : 'none'));
          link.style('opacity', 0.1);

          // Zoom in and center the matched node
          const zoomLevel = 2;
          const x = matchedNode.x;
          const y = matchedNode.y;
          const translateX = width / 2 - x * zoomLevel;
          const translateY = height / 2 - y * zoomLevel;

          const transform = d3.zoomIdentity
            .translate(translateX, translateY)
            .scale(zoomLevel);

          svg.transition().duration(600).call(zoom.transform, transform);

          // Reset the simulation alpha target
          simulation.alphaTarget(0);
          setIsNodeSearching(false);
        });
      }
    }

    // Add a click listener to the SVG to reset the graph when clicking on empty space
    svg.on('click', () => {
      if (searchProbe) {
        resetGraph(); // Reset graph when clicking on the SVG (empty space)
      }
    });

    return () => {
      svg.selectAll('*').remove();
    };
  }, [selectedGraph, searchProbe, selectedEntityType, searchedEntitiesInGraph]);

  return (
    <div>
      <div className="cl-graph-searchinput-main">
        <div className='cl-graph-LeftContainer'>
          <div onClick={goBackToSearchEntitiesPage} className="cl-graph-LeftButton-main">
            <img src={BackIcon} alt="Icon" className="cl-graph-LeftButton-main-icon" />
            New Network
          </div>          <div className="cl-graph-LeftButton-main-active">Adidas UK Limited</div>
          <div onClick={exportSelectedGraph}
            className="cl-graph-LeftButton-main">
            <img src={ExportIcon} alt="Icon" className="cl-graph-LeftButton-export-icon" />
            Export
          </div>
        </div>
        <div className='cl-graph-RightContainer'>
          <CLSearchInput
            nodes={selectedGraph && selectedGraph?.nodes}
            onSuggestionSelect={onSuggestionSelect}
            isNodeSearching={isNodeSearching}
          />
          <div className="connection-level-container">
            <div
              className="connection-level-header"
              onClick={toggleSlider}
            >
              Connections Level
              <img src={arrowDown} alt="Icon" style={{ marginLeft: '10px', cursor: 'pointer' }} />
            </div>
            {showSlider && (
              <div className="connection-level-slider-container">
                Level 1
                <input
                  type="range"
                  min="1"
                  max="3"
                  defaultValue="1"
                  className="connection-level-slider-vertical"
                  orient="vertical" // Key for vertical slider in some browsers
                />
              </div>
            )}
          </div>

        </div>
        {/* <div className="cl-graph-desktop-view">
              <CLGraphTotalDropdown
                graphList={graph}
                setSelectedGraph={setSelectedGraph}
              />
            </div> */}
      </div>
      <span></span>
      <svg ref={svgRef} className="cl-graph-d3-svg"></svg>
      {graph && (
        <div>
          <div className="cl-graph-rightsidebarmenu-main">
            <BottomMenu
              zoomIn={zoomIn}
              zoomValue={zoomValue}
              zoomOut={zoomOut}
              isSidebarHidden={isSidebarHidden}
              setIsFilterOpened={setIsFilterOpened}
            />
          </div>
          <div
            style={{
              position: 'absolute',
              top: '25%',
              left: 20,
            }}
            className="cl-graph-leftsidebar-itemholder"
          >
            <CLGrapgLeftSidebarItemHolder
              setLeftSideButtonType={setLeftSideButtonType}
              isLoadingShareholders={isLoadingShareholders}
              setIsSidebarHidden={setIsSidebarHidden}
            />
          </div>
          <div
            style={{
              display: 'none'
            }}
            className="cl-graph-mobile-view cl-graph-total-dropdown"
          >
            <CLGraphTotalDropdown
              graphList={graph}
              setSelectedGraph={setSelectedGraph}
            />
          </div>
          {/* <div
            style={{
              position: 'absolute',
              bottom: 90,
              right: 10,
            }}
          >
            <div className="cl-graph-mobile-view">
              <ProbeButton
                width={50}
                height={50}
                iconSrc={<ArrowBackIcon />}
                iconType={'svg'}
                borderRadius={50}
                onClick={goBackToSearchEntitiesPage}
              />
            </div>
          </div> */}
        </div>
      )}
      {isFilterOpened && <div className='cl-graph-modal-container'>

        <div className='cl-graph-modal-header'>
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Filters</span>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }} onClick={() => setIsFilterOpened(false)} >x</span>
        </div>
        {/* Filters */}
        <div className='cl-graph-modal-filters'>
          {filterOptions.map((filter, index) => (
            <label className='cl-graph-modal-filter' key={index}>
              <input
                type="checkbox"
                checked={selectedFilter === filter.text} // Check if this checkbox is selected
                onChange={() => handleCheckboxChange(filter.text)}
                style={{
                  width: '20px',
                  height: '20px',
                  cursor: 'pointer',
                  borderRadius: '20%',
                }}
              />
              {filter.text}
            </label>
          ))}
        </div>

        {/* Filter Button */}
        <button
          className='cl-graph-modal-filter-button'
          style={{
            background: selectedFilter
              ? 'linear-gradient(to bottom right, #0466D1, #00D1A9)'
              : '#CFCFCF',
            cursor: selectedFilter ? 'pointer' : 'not-allowed',
            transition: 'background 0.3s ease',
          }}
          disabled={!selectedFilter} // Disable if no filter is selected
          onClick={handleFilterButtonClick}
        >
          Filter
        </button>
      </div>}
    </div>
  );
};

export default CLGraph;
