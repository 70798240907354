import React from 'react';
import './CLGrapgLeftSidebarItem.css';
import loadingGif from '../../../../assets/images/loading.gif'; // Adjust the path to where your GIF is located

const CLGrapgLeftSidebarItem = ({
  icon,
  highlightedIcon,
  text,
  isActive,
  onClick,
  isLoadingShareholders,
}) => {
  return (
    <div
      className={`corporate-links-Graph-sidebar-item ${isActive ? 'active' : ''} ${isLoadingShareholders && text === 'Shareholders' ? 'corporate-links-Graph-sidebar-item-disable' : ''}`}
      onClick={onClick}
    >
      {isLoadingShareholders && text === 'Shareholders' ? (
        <div>
          <img
            className="corporate-links-Graph-sidebar-item-icon-loading"
            src={loadingGif}
            alt="[..]"
          />
        </div>
        ) : (
          <div>
            { isActive === true ? (
              <img
              className="corporate-links-Graph-sidebar-item-icon"
              src={highlightedIcon}
              alt="[..]"
              />
            ) : (
              <img
            className="corporate-links-Graph-sidebar-item-icon"
            src={icon}
            alt="[..]"
            />) 
            }
          </div>
      )}

      <div className="corporate-links-Graph-sidebar-item-description">
        {text}
      </div>
    </div>
  );
};

export default CLGrapgLeftSidebarItem;
