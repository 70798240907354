import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import { getMonthName } from '../../../../utils/common';
import { nationalityToFlag, statusDiv } from '../../../../utils/helper';
const PscItem = ({ pscData, isCardView = false }) => {
  return (
    <>
      {pscData &&
        pscData?.map(item => (
          <div
            className={` ${
              isCardView
                ? 'company-info-modal-person-list-is-card-view'
                : 'company-info-modal-person-list'
            }`}
          >
            <div className="company-info-modal-person-list-info">
              <div style={{ display: 'flex' }}>
                <PersonIcon fontSize="small" />
                <div style={{ width: '100%', marginLeft: '10px' }}>
                  <div
                    className="company-info-modal-overview-key"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '2px',
                    }}
                  >
                    {item?.name}
                    <div className="company-info-modal-person-list-info-link-buttons">
                      <div>
                        {item?.ceased_on
                          ? statusDiv('ceased')
                          : statusDiv('active')}
                      </div>
                      <div>
                        <a
                          href={`https://www.linkedin.com/search/results/all/?keywords=${item?.name}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedInIcon style={{ width: '20px' }} />
                        </a>
                      </div>
                      <div>
                        <a
                          href={`https://www.google.com/search?q=${item?.name}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LanguageIcon style={{ width: '20px' }} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="company-info-modal-overview-value company-info-modal-overview-value-people-list company-info-modal-overview-value-with-flex">
                <div>
                  {nationalityToFlag[
                    item?.nationality?.toUpperCase().replace(' ', '')
                  ] && (
                    <img
                      src={
                        nationalityToFlag[
                          item?.nationality?.toUpperCase().replace(' ', '')
                        ]
                      }
                      alt={`${item?.nationality} flag`}
                      style={{
                        width: '15px',
                        height: '10px',
                      }}
                    />
                  )}

                  {'   '}
                  {item?.nationality && (
                    <span style={{ marginLeft: '2px' }}>
                      {item?.nationality?.toUpperCase().replace(' ', '')}
                    </span>
                  )}
                </div>

                <div style={{ display: 'flex', gap: '5px' }}>
                  <div>
                    {item?.country_of_residence && <span>{item?.country_of_residence}</span>}
                  </div>
                  <div>
                    {item?.occupation && <span>{item?.occupation}</span>}
                  </div>
                </div>
                <div>
                  {item?.date_of_birth?.month && item?.date_of_birth?.year && (
                    <span>
                      Born in&nbsp;
                      {getMonthName(item?.date_of_birth?.month)}
                      &nbsp;
                      {item?.date_of_birth.year}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default PscItem;
