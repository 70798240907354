import React from 'react';
import { useLocation } from 'react-router-dom';
import CEIDisableAndSignificantControl from '../../components/CorporateExploreInsights/CEIDisableAndSignificantControl/CEIDisableAndSignificantControl';
import CEIShareholder from '../../components/CorporateExploreInsights/CEIShareholder/CEIShareholder';
import WatchList from '../../components/CorporateExploreInsights/CEIWatchList/CEIWatchList';
import CIEDocument from '../../components/CorporateExploreInsights/CIEDocument/CIEDocument';
import CEIPeople from '../../components/CorporateExploreInsights/CEIPeople/CEIPeople';
import CEILatestActivity from '../../components/CorporateExploreInsights/CEILatestActivity/CEILatestActivity';
import CIEContact from '../../components/CorporateExploreInsights/CIEContact/CIEContact';
import CEIKeyData from '../../components/CorporateExploreInsights/CEIKeyData/CEIKeyData';
import CEIFinancials from '../../components/CorporateExploreInsights/CEIFinancials/CEIFinancials';
import './CorporateExploreInsight.css';
import CorporateExploreInsightLogo from './CorporateExploreInsightLogo/CorporateExploreInsightLogo';

function CorporateExploreInsightOverview({
  selectedCompany,
  accountInfo,
  forceMobileView,
}) {
  const location = useLocation();

  return (
    <div className="col-lg-12">
      {selectedCompany && (
        <div
          className={`corporate-explore-insight-gridContainer ${
            forceMobileView
              ? 'corporate-explore-insight-gridContainer-forece-mobileview'
              : ''
          }`}
        >
          {location.pathname !==
            '/cei-dashboard/cei-corporate-explore-insight' && (
            <div className="corporate-explore-insight-gridItem">
              <CorporateExploreInsightLogo selectedCompany={selectedCompany} />
            </div>
          )}
          <div className="corporate-explore-insight-gridItem">
            <CEIKeyData selectedCompany={selectedCompany} />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEIFinancials
              selectedCompany={selectedCompany}
              accountInfo={accountInfo}
            />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CIEContact selectedCompany={selectedCompany} />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEILatestActivity />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEIPeople selectedCompany={selectedCompany} />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CIEDocument selectedCompany={selectedCompany} />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEIDisableAndSignificantControl
              people=" People And Significant Control"
              icon="../../assets/images/social/double-user.png"
              title="Upgrade to view People with Significant Control"
              message="Access unlimited, real-time insights for any UK company — including 10 Years Financials, Credit Profiles, Owner Structures, and more on-demand"
              buttonText="PLANS & PRICING"
              selectedCompany={selectedCompany}
            />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEIDisableAndSignificantControl
              people=" Group Structure"
              icon="../../assets/images/social/structure.png"
              title="Upgrade to view Group Structure"
              message="Access unlimited, real-time insights for any UK company — including 10 Years Financials, Credit Profiles, Owner Structures, and more on-demand"
              buttonText="PLANS & PRICING"
            />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEIShareholder selectedCompany={selectedCompany} />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <CEIDisableAndSignificantControl
              people="Credit Score and Limit"
              icon="../../assets/images/social/pie.png"
              title="Upgrade to view Group Structure"
              message="Access unlimited, real-time insights for any UK company — including 10 Years Financials, Credit Profiles, Owner Structures, and more on-demand"
              buttonText="PLANS & PRICING"
            />
          </div>
          <div className="corporate-explore-insight-gridItem">
            <WatchList selectedCompany={selectedCompany} />
          </div>
        </div>
      )}
    </div>
  );
}

export default CorporateExploreInsightOverview;
