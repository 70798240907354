import './HeaderDropdown.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ProfileAvatar from '../assets/images/icons/avatar-demo.png';
import MyProfileIcon from '../assets/images/icons/my-profile-icon.svg';
import CustomerAdminIcon from '../assets/images/icons/customer-admin-icon.svg';
import Settings from '../assets/images/icons/settings.png';
import ReportIcon from '../assets/images/report.png';
import SuggestionIcon from '../assets/images/icons/bx_chat.svg';
import HelpIcon from '../assets/images/icons/Help.svg';
import { AppContext } from '../context/Context';
import { useLocation } from 'react-router-dom';
import './HeaderDropdownNative.css';
import { useAuth } from '../context/AuthProvider';
import zIndex from '@mui/material/styles/zIndex';
import boltIcon from '../assets/images/icons/boltIcon.png';

function HeaderDropdown() {
  const location = useLocation();
  const { user } = useContext(AppContext);
  const { logout, openLogin, openProfile } = useAuth();
  const navigate = useNavigate();

  const loginUser = () => {
    openLogin();
  };

  const logoutUser = () => {
    logout();
  };

  const userSettings = () => {
    openProfile();
  };

  const handleMenuClick = path => {
    navigate(path);
  };

  return (
    <div className="name-div">
      {!location.pathname === '/' && location.pathname !== '/home' && (
        <div
          className="make-a-suggestion-div"
          data-tooltip-id="suggestion-tooltip"
        >
          <button
            onClick={() => {
              navigate('/fbf-dashboard/feedbackform');
            }}
            className="body-text-normal"
          >
            <img
              style={{ marginRight: '5px' }}
              src={SuggestionIcon}
              alt="icon here"
            />
            <span className="help-text-large">Make a Suggestion</span>
            <span className="help-text-small">Suggestion</span>
          </button>
        </div>
      )}
      <div data-tooltip-id="help-request-tooltip">
        <button class="custom-gradient-button">Submit Help Request</button>
      </div>
      <div class="user-plan-button" data-tooltip-id="User-plan-tooltip">
        <img src={boltIcon} alt="bolt-icon" />
        <button style={{ backgroundColor: '#fff', border: 'none' }}>
          Upgrade Now
        </button>
      </div>
      {/* <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="dropdown">
          <div
            className="btn dropdown-toggle header-dropdown-native-userdropdown"
            href="#"
            role="button"
            id="dropdownMenuLink"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {user && !user?.guest ? (
              <div
                className="header-dropdown-native-userdropdown-initials"
                data-tooltip-id="profile-tooltip"
              >
                <img
                  src={
                    user.ProfileImageS3Url
                      ? user.ProfileImageS3Url
                      : ProfileAvatar
                  }
                  alt={ProfileAvatar}
                />
              </div>
            ) : null}

            {user && !user?.guest ? (
              <div
                className="name-greetings dark-heading-color"
                data-tooltip-id="profile-tooltip"
              >
                Hi {user.FullName && <>, {user.FullName}</>}
              </div>
            ) : null}

            {!user || user?.guest ? (
              <div style={{ display: 'flex', gap: '10px', alignItems:'center', }}>
                <img className='greetings-avatar' src="https://s3-alpha-sig.figma.com/img/0c26/d887/ed060b47018885c4c6847048f8a83758?Expires=1736726400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=HvAUPyAOtA03TnIzknV9VY69lXoY8ivZcfivWDyDBfC0IOXkIX8ywDCMeKryb0N4COvQeRFVmd4BkAOEpnB~BsN3jGP28sYg5qyXkW4HmroAEVAA6AZqP8zV34AUvGkcl79rD80p4FVSI0B-70xV~qw2fDVxnwov1fNwDnlh4KcvONZTYK8Ro3qFyUTifgn3ZRLrw0MFECV9kXG60733uqmLic-pIk7ogcbSV~CkjEkATTsGHKjnRyzmKuavmFSdGevbUwna2C10akzMNiH~CUdkznBWJiGA4qUjw6PDTAhv8K6eFJARBnHrs5m3Oxws1TI6VQEgenk9Htj1v5BFHw__" alt="IMG" />
                <div className="name-greetings dark-heading-color">
                  Guest User
                </div>
              </div>
            ) : null}
          </div>

          {user && !user?.guest ? (
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <div className="dropdown-item" onClick={userSettings}>
                <li>
                  <div>
                    <img src={MyProfileIcon} alt="icon here" />
                  </div>
                  <div>Profile</div>
                </li>
              </div>

              {user?.FirebaseUser?.role === 'admin' && (
                <div
                  className="dropdown-item"
                  onClick={() => handleMenuClick('/profile/customer-admin')}
                >
                  <li>
                    <div>
                      <img src={CustomerAdminIcon} alt="icon here" />
                    </div>
                    <div>Customer Administration</div>
                  </li>
                </div>
              )}

              <div
                className="dropdown-item"
                onClick={() =>
                  handleMenuClick('/profile/judgement-checker-reports')
                }
              >
                <li>
                  <div>
                    <img src={ReportIcon} alt="report" />
                  </div>
                  <div>Reports</div>
                </li>
              </div>

              <div
                className="dropdown-item"
                onClick={() => handleMenuClick('/profile/accounts-usage')}
              >
                <li>
                  <div>
                    <img src={Settings} alt="icon here" />
                  </div>
                  <div>Usage</div>
                </li>
              </div>

              <div className="dropdown-item" onClick={logoutUser}>
                <li>
                  <div>
                    <img src={CustomerAdminIcon} alt="icon here" />
                  </div>
                  <div>Logout</div>
                </li>
              </div>
            </ul>
          ) : null}

          {!user || user?.guest ? (
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <div className="dropdown-item">
                <li onClick={loginUser}>
                  <div>
                    <img src={MyProfileIcon} alt="icon here" />
                  </div>
                  <div> Login </div>
                </li>
              </div>
            </ul>
          ) : null}
        </div>
      </div> */}
    </div>
  );
}

export default HeaderDropdown;
